import React from "react";
import { PropTypes } from "prop-types";
import { Cell, KeyIndicator } from "@wingmate/graphs";
import { AnalyticsDoorSunriseIcon } from "@wingmate/graphics";

export function ClosedLostCell({ failed }) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const displayValue = failed?.current || 0;
  const previousValue = failed?.previous || 0;

  let trendPercentage = 0;
  if (previousValue > 0) {
    trendPercentage = -1 * (displayValue / previousValue - 1) * 100;
  }

  return (
    <Cell
      rowspan={1}
      colspan={1}
      position={{ col: 3, row: 1 }}
      icon={<AnalyticsDoorSunriseIcon width={44} height={44} />}
      color="#FFDFE3"
    >
      <KeyIndicator
        title="Closed Lost"
        value={formatter.format(displayValue)}
        trendValue={formatter.format(previousValue)}
        trendPercentage={trendPercentage}
        trendLabel="Previous"
      />
    </Cell>
  );
}

ClosedLostCell.propTypes = {
  failed: PropTypes.shape({
    current: PropTypes.string,
    previous: PropTypes.string,
  }),
};

export default ClosedLostCell;
