export const urlEncodeParams = (data) => {
  const params = new URLSearchParams();

  Object.keys(data).map((key) => {
    const value = data[key];

    if (Array.isArray(data[key])) {
      const arrayKey = `${key}[]`;
      value.map((item) => params.append(arrayKey, item));
    } else {
      params.append(key, value);
    }
    return key;
  });

  return params.toString();
};

export default {
  urlEncodeParams,
};
