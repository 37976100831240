import React from "react";
import { PropTypes } from "prop-types";
import { AreaChart, Cell } from "@wingmate/graphs";
import { Text } from "@wingmate/toolkit";
import { AnalyticsHandshakeIcon } from "@wingmate/graphics";

export function HealthCell({ health }) {

  return (
    <Cell
      rowspan={2}
      colspan={2}
      position={{ col: 1, row: 2 }}
      icon={<AnalyticsHandshakeIcon width={44} height={44} />}
      color="#DAF3FE"
    >
      <Text type="P" weight="Medium" className="KeyIndicator__Title AnalyticsTitle">Lead Health</Text>
      <br />
      <br />
      <AreaChart
        data={health || []}
        xKey={'date'}
        areaKeys={[
          { key: 'orange', color: '#F2AC87', fillOpacity: 1 },
          { key: 'yellow', color: '#F5DC75', fillOpacity: 1 },
          { key: 'green', color: '#7EC7AB', fillOpacity: 1 },
        ]}
        xAxisLabel={'Date'}
        yAxisLabel={'Value'}
        stacked
      />
      {
        health && health.length > 0 && (
          <Text>Red Count: {health[health.length - 1]["red"]}</Text>
        )
      }
    </Cell>
  );
}

HealthCell.propTypes = {
  health: PropTypes.object,
};

export default HealthCell;
